import { DialogProps } from '@radix-ui/react-dialog';
import { useControllableState } from '@radix-ui/react-use-controllable-state';
import { useMutation } from '@tanstack/react-query';
import { LoaderCircleIcon } from 'lucide-react';
import { type ReactNode, useEffect, useId } from 'react';
import {
  DefaultValues,
  FieldValues,
  type UseFormHandleSubmit,
  UseFormReturn,
} from 'react-hook-form';

import { useHideMobileIntercomDialogOpen } from '@/old/utils/thirdPartyAPIs/intercom';
import { cn } from '@/utils/tailwind';
import { tanstackErrorHandler } from '@/utils/tanstack-client';

import { ConfirmationDialog } from './confirmation-dialog';
import { Button } from './ui/button';
import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from './ui/dialog';
import { Form } from './ui/form';

interface HandlerParams {
  setIsOpen: React.Dispatch<React.SetStateAction<boolean | undefined>>;
}

export interface FormDialogProps<
  I extends FieldValues = FieldValues,
  O extends FieldValues = I,
> extends React.PropsWithChildren {
  dialogTitle: React.ReactNode;
  dialogDescription?: React.ReactNode;
  form: UseFormReturn<I, O>;
  // This prop has to be memoized, we need a stable reference
  defaultValues: DefaultValues<NoInfer<I>>;
  submitLabel?: string;
  submitProgressLabel?: string;
  cancelLabel?: string;

  showDelete?: boolean;
  deleteLabel?: string;
  deleteProgressLabel?: string;
  deleteConfirmTitle?: string;
  deleteConfirmDescription?: string;
  deleteConfirmMessage?: ReactNode;

  errorToastLabel?: string;
  isFullPageDialog?: true;
  triggerElement?: React.ReactNode;

  open?: DialogProps['open'];
  defaultOpen?: DialogProps['defaultOpen'];
  onOpenChange?: DialogProps['onOpenChange'];

  submitHandler: (
    params: {
      data: NoInfer<O>;
    } & HandlerParams,
  ) => Promise<void>;
  deleteHandler?: (params: HandlerParams) => Promise<void>;
}

export const FormDialog = <
  I extends FieldValues = FieldValues,
  O extends FieldValues = I,
>(
  props: FormDialogProps<I, O>,
) => {
  const {
    children,
    dialogTitle,
    dialogDescription,
    form,
    defaultValues,
    submitLabel = 'Submit',
    submitProgressLabel = 'Submitting',
    cancelLabel = 'Cancel',
    showDelete = false,
    deleteLabel = 'Delete',
    deleteProgressLabel = 'Deleting',
    deleteConfirmTitle = 'Are you sure?',
    deleteConfirmDescription = 'This action cannot be undone',
    deleteConfirmMessage,
    isFullPageDialog,
    errorToastLabel = 'Request failed',
    triggerElement,
    open,
    defaultOpen,
    onOpenChange,
    submitHandler,
    deleteHandler,
  } = props;

  const id = useId();

  const [isOpen = false, setIsOpen] = useControllableState({
    prop: open,
    defaultProp: defaultOpen,
    onChange: onOpenChange,
  });

  useHideMobileIntercomDialogOpen(isOpen);

  const { isPending, mutate } = useMutation({
    mutationFn: async (params: Parameters<typeof submitHandler>[0]) => {
      try {
        await submitHandler(params);
      } catch (error) {
        tanstackErrorHandler(error as Error, errorToastLabel);
      }
    },
  });

  const { isPending: isDeletePending, mutate: handleDelete } = useMutation({
    onMutate: async (params: HandlerParams) => {
      try {
        await deleteHandler?.(params);
      } catch (error) {
        tanstackErrorHandler(error as Error, errorToastLabel);
      }
    },
  });

  const onSubmit = async (data: O) => {
    mutate({ data, setIsOpen });
  };

  const onDelete = async () => {
    handleDelete({ setIsOpen });
  };

  useEffect(() => {
    if (isOpen) {
      form.reset(defaultValues);
    } else {
      form.reset();
    }
  }, [defaultValues, form, isOpen]);

  const formHandleSubmit = form.handleSubmit as unknown as UseFormHandleSubmit<
    I,
    O
  >;

  return (
    <Form {...form}>
      <Dialog open={isOpen} onOpenChange={setIsOpen}>
        <DialogTrigger asChild onClick={() => setIsOpen(true)}>
          {triggerElement}
        </DialogTrigger>

        <DialogContent
          className={cn(isFullPageDialog && '[&>button:last-child]:hidden')}
        >
          <DialogHeader>
            <DialogTitle>{dialogTitle}</DialogTitle>
            {DialogDescription && (
              <DialogDescription>{dialogDescription}</DialogDescription>
            )}
          </DialogHeader>

          <form
            id={`form-dialog-${id}`}
            className="flex flex-col gap-4"
            onSubmit={formHandleSubmit(onSubmit)}
          >
            {children}
          </form>

          <DialogFooter className="gap-2">
            {showDelete && (
              <ConfirmationDialog
                title={deleteConfirmTitle}
                description={deleteConfirmDescription}
                message={deleteConfirmMessage}
                onConfirm={onDelete}
              >
                <Button variant="destructive" className="sm:mr-auto">
                  {isDeletePending ? (
                    <>
                      <LoaderCircleIcon className="animate-spin" />{' '}
                      {deleteProgressLabel}
                    </>
                  ) : (
                    deleteLabel
                  )}
                </Button>
              </ConfirmationDialog>
            )}

            {!isFullPageDialog && (
              <DialogClose asChild>
                <Button variant="ghost">{cancelLabel}</Button>
              </DialogClose>
            )}

            <Button
              type="submit"
              form={`form-dialog-${id}`}
              className="flex gap-2"
              disabled={isPending}
            >
              {isPending ? (
                <>
                  <LoaderCircleIcon className="animate-spin" />{' '}
                  {submitProgressLabel}
                </>
              ) : (
                submitLabel
              )}
            </Button>
          </DialogFooter>
        </DialogContent>
      </Dialog>
    </Form>
  );
};
